import React, { useEffect, useState } from 'react'

import Cube                 from '../components/Cube'
import ConnectWithUs        from '../components/ConnectWithUs'
import Services             from '../components/Services'
import Portfolio            from '../components/Portfolio'
import TrustPilot           from '../components/TrustPilot'

import './LandingScreen.css'


const LandingScreen = () => {

    const [isMobile, setIsMobile] =
        useState(window.matchMedia('(max-width: 767px)').matches);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 767px)');

        const handleMediaChange = (e) => {
            setIsMobile(e.matches);
        };

        mediaQuery.addListener(handleMediaChange);

        return () => {
            mediaQuery.removeListener(handleMediaChange);
        };
    }, []);


  return (
    <>
        <div className="page" id="LandingScreen">

            <div className="underlay">
            {
                ! isMobile && <Cube />
            }
            </div>

            <div className="content" id="interface">

                <div className="text-center">

                    <div id="logo" className=" unique animate__animated animate__slideInRight">
                        <img src="img/dramallama_logo_small.png" alt="dramallama.limited" width="300px" />
                    </div>

                        <h1>dramallama limited</h1>

                        <Services />

                        <Portfolio />

                        <ConnectWithUs />

                    <TrustPilot />

                    <p className="small">&copy; 2023-4 dramallama.limited, all rights reserved.</p>

                </div>

            </div>
        </div>

    </>
  );
};

export default LandingScreen;
