import React from 'react'

import SocialMediaLinks     from './SocialMediaLinks'

// <ul>
//     <li>Unit 55 Enterprise Park</li>
//     <li>Newtownabbey</li>
//     <li>Co. Antrim</li>
//     <li>BT36 4GN</li>
//     <li>Northern Ireland</li>
//     <li>United Kingdom</li>
// </ul>

const ConnectWithUs = () => {

    const emailAddress = "enquiries [at] dramallama.ltd";

    return (
    <>
    <div id="connect_with_us" className="animate__animated animate__slideInLeft">
        <h2>Connect</h2>

        <SocialMediaLinks />

        <ul>
            <li className="email">
                <a href="mailto:enquiries@dramallama.ltd">
                    { emailAddress }
                </a>
            </li>
        </ul>

    </div>
    </>
    )
}

export default ConnectWithUs
