import React from 'react'


const Portfolio = () => {

    return (
    <>
    <div className="portfolio animate__animated animate__slideInUp">
        <h2>Recent Portfolio</h2>

        <div className="thumbnailRow">
            <a href="https://www.ecomaccountingsolutions.com">
                <img src="img/squeezed-ecomaccountingsolutions.jpg" alt="eCom Accounting Solutions" className="thumbnail" />
            </a>

            <a href="https://www.edenandross.com" >
                <img src="img/squeezed-edenandross.jpg" alt="Eden and Ross Bathrooms" className="thumbnail" />
            </a>

            <a href="https://www.osullivanplaybeds.co.uk" >
                <img src="img/squeezed-osullivanplaybeds.jpg" alt="O'Sullivan Playbeds" className="thumbnail" />
            </a>

            <a href="https://bewitched.cleaning" >
                <img src="img/squeezed-bewitched.jpg" alt="Bewitched Cleaning" className="thumbnail" />
            </a>
        </div>

        <ul className="textLinks">
            <li><a href="https://www.ecomaccountingsolutions.com">eCom Accounting Solutions</a></li>
            <li><a href="https://www.edenandross.com">Eden and Ross Bathrooms</a></li>
            <li><a href="https://www.osullivanplaybeds.co.uk">O&apos;Sullivan Playbeds</a></li>
            <li><a href="https://bewitched.cleaning">Bewitched Cleaning</a></li>
        </ul>

    </div>
    </>
    )
}

export default Portfolio
