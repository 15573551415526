
import './App.css';

import LandingScreen from './screens/LandingScreen.js'


function App() {
  return (
    <>
      <LandingScreen />
    </>
  );
}

export default App;
