import React from 'react'

const TrustPilot = () => {
    return (
    <>
    <div id="trustPilot">
        <div className="trustpilot-widget" data-locale="en-GB" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="65354d8c91ace5052a3a9960" data-style-height="52px" data-style-width="100%">
          <a href="https://uk.trustpilot.com/review/dramallama.ltd" target="_blank" rel="noopener">TrustPilot</a>
        </div>
    </div>
    </>
    )
}

export default TrustPilot
