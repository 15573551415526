import React from 'react'

const SocialMediaLinks = () => {

    const facebookLink  = process.env.REACT_APP_FB_LINK;
    const twitterLink   = process.env.REACT_APP_TWITTER_LINK;
    const youtubeLink   = process.env.REACT_APP_YOUTUBE_LINK;
    const instagramLink = process.env.REACT_APP_INSTAGRAM_LINK;

    return (
    <>
        <section id="SocialMediaLinks">

            <div className="SocialMediaLinks">

                <a className="me-4" href={twitterLink}>
                  <i className="bi bi-twitter"></i>
                </a>

                <span className="me-4"></span>

                <a className="me-4" href={facebookLink}>
                  <i className="bi bi-facebook"></i>
                </a>

                <span className="me-4"></span>

                <a className="me-4" href={youtubeLink}>
                  <i className="bi bi-youtube"></i>
                </a>

                <span className="me-4"></span>

                <a className="me-4" href={instagramLink}>
                  <i className="bi bi-instagram"></i>
                </a>

                <span className="me-4"></span>

            </div>

       </section>
    </>
    )
}

export default SocialMediaLinks
