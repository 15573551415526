import React, { useState, useEffect } from 'react'
import * as THREE from 'three';


class Renderer {
    constructor (w, h) {
        this.w_         = w;
        this.h_         = h;
        this.renderer_  = undefined;

        this.createRenderer();
    }

    __destroy__ = () => {
        this.renderer_.dispose();
    }

    createRenderer = () => {
        this.renderer_  = new THREE.WebGLRenderer({ antialias: true });
        this.renderer_.onContextLost    = this.onContextLost;
        this.renderer_.onContextRestore = this.onContextRestore;

        this.setSize(this.w_, this.h_);
    }

    onContextLost = (event) => {
        event.preventDefault();
        console.log('WebGL context lost');
        this.renderer_.dispose();
    }

    onContextRestore = (event) => {
        console.log('WebGL context restored');
        this.createRenderer();
    }

    setSize = (size) => {
        this.w_ = size.x;
        this.h_ = size.y;

        this.renderer_.setSize(size.x, size.y);
        this.renderer_.setPixelRatio(window.devicePixelRatio);
    }

    setClearColor = (c) => {
        this.renderer_.setClearColor(c);
    }

    render = (scene, camera) => {
        this.renderer_?.render(scene, camera);
    }
}


class CubeScene {

    constructor (w, h) {
        this.w_ = w;
        this.h_ = h;

        this.scene_         = undefined;
        this.camera_        = undefined;
        this.cube_          = undefined;
        this.cubeShader_    = undefined;
        this.light_         = undefined;

        this.createScene();
        this.createLights();
        this.createCube();

        this.scene_.add(this.cube_);
        this.cube_.position.set(0, 0, 0);

        this.camera_.position.z = 1.1;
    }

    __destroy__ = () => {
    }

    createScene = () => {
        this.scene_     = new THREE.Scene();
        this.camera_    = new THREE.PerspectiveCamera(75, this.w_/this.h_, 0.1, 1000);
    }

    onContextLost = (event) => {
        event.preventDefault();
    }

    onContextRestore = (event) => {
    }

    setSize = (size) => {
        this.w_ = size.x;
        this.h_ = size.y;
        this.camera_.aspect = size.x / size.y;
        this.camera_.updateProjectionMatrix();
    }


    createLights = () => {
        this.light_ = new THREE.PointLight(0xffffff, 1, 100);
    }

    createCube = () => {
        const vertexShader = `
            varying vec2 vUv;

            void main() {
                vUv = uv;   // texture coords in the range 0..1
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
        `;

        const fragmentShader = `
            varying vec2    vUv;
            uniform float   u_width;
            uniform float   u_height;
            uniform float   u_time;

            const float     escapeRadius        = 2.0;    // default is 2
            const int       maxIter             = 187;      // default is 200
            const float     reciprocalMaxIter   = 1.0 / float(maxIter);

            vec3 color(float t) {
                t = clamp(t, 0.0, 1.0);

                return vec3(
                    smoothstep(0.0, 0.1, t) +
                    smoothstep(0.9, 0.99, t),

                    smoothstep(0.2, 0.3, t) +
                    smoothstep(0.7, 0.8, t),

                    smoothstep(0.1, 0.2, t) +
                    smoothstep(0.8, 0.9, t)
                );
            }

            vec4 julia ()
            {
                vec2 move   = vec2(2.0, -0.5);
                float zoom  = 0.15;

                vec2 c = vec2(-0.87, 0.27015);
                //vec2 c = vec2(-0.7, 0.27015);

                vec2 z = vec2 (
                    (zoom * (move.x + vUv.x) - 0.5),
                    (zoom * (move.y + vUv.y) - 0.5)
                );

                int i;
                for (i = 0; i < maxIter; ++ i)
                {
                    float x = ((z.x * z.x) - (z.y * z.y)) + c.x;
                    float y = 2.0 * (z.x * z.y) + c.y;

                    if ((x*x + y*y) > 10.0)
                        break;

                    z = vec2(x,y);
                }

                float t = float(i) * reciprocalMaxIter;
                t = (t == 1.0) ? 0.0 : t;

                // gl_FragColor = vec4(color(t), 1.0);
                return vec4(color(t), 1.0);
            }

            void mandelbrot() {
                vec2 c = (vUv - vec2(0.5)) * 2.0;
                vec2 z = vec2(0.0);

                float iteration = 0.0;

                for (int i = 0; i < maxIter; ++i)
                {
                    iteration += 1.0;

                    if (dot(z, z) > escapeRadius)
                        break;

                    z = vec2(z.x * z.x - z.y * z.y, 2.0 * z.x * z.y) + c;
                }

                float t = iteration * reciprocalMaxIter;
                t = (t == 1.0) ? 0.0 : t;

                gl_FragColor = vec4(color(t), 1.0);
            }

            void main() {
                //vec2 q = gl_FragCoord.xy / vec2(u_width, u_height).xy ;  // texture coords in the range 0..1
                //vec2 p = -1.0 + (2.0*q);
                // float range          = smoothstep(-0.25, 0.25, p.x);
                // float f              = fbm(4.0 * p);
                // vec3 noiseGradient   = vec3(f,f,f);
                // gl_FragColor         = vec4(range * noiseGradient, 1.0);
                // gl_FragColor = background;

                vec4 juliaColor   = julia();
                gl_FragColor      = vec4(juliaColor.xyz * 0.35, 1.0);
            }
        `;

        this.cubeShader_ = new THREE.ShaderMaterial({
            uniforms: {
                u_time:   { value: 0 },
                u_width:  { value: this.w_ },
                u_height: { value: this.h_ },
            },

            vertexShader:   vertexShader,
            fragmentShader: fragmentShader
        });

        this.cube_ = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), this.cubeShader_);
    }

    animate = () => {
        this.cube_.rotation.x += 0.002;
        this.cube_.rotation.y += 0.0025;
    }
}



const Cube = () => {

    let surface = null;


    const [surfaceSize, setSurfaceSize] = useState({"x": 512, "y": 512});

    const sceneMgr  = new CubeScene(surfaceSize.x, surfaceSize.y);
    const renderMgr = new Renderer(surfaceSize.x, surfaceSize.y);

    // const controls = new OrbitControls(sceneMgr.camera_, sceneMgr.renderer_.domElement);

    const onWindowResize = () => {
        const size =
            {"x": window.innerWidth, "y": window.innerHeight};

        setSurfaceSize(size);
        renderMgr.setSize(size);
        sceneMgr.setSize(size);
    };

    const animate = () => {
        requestAnimationFrame(animate);
        sceneMgr.animate();
        renderMgr.render(sceneMgr.scene_, sceneMgr.camera_);
    };


    useEffect(() => {

        const size =
            {"x": window.innerWidth, "y": window.innerHeight};

        renderMgr.setSize(size);
        sceneMgr.setSize(size);

        renderMgr.setClearColor('#1a1a4a');

        if (surface.childNodes.length === 0)
            surface.appendChild(renderMgr.renderer_.domElement);

        onWindowResize();

        window.addEventListener('resize', onWindowResize, false);

        animate();

        return () => {
            window.removeEventListener('resize', onWindowResize, false);
        };
    }, [surface]);

  return (
    <div
        ref={(el) => (surface = el)}
        style={{ width: surfaceSize.x, height: surfaceSize.y }}
    />
  );
};

export default Cube;

